'use client';
import { signOut } from 'next-auth/react';
import Link from 'next/link';
import { cn, isBilledUser } from '@/app/lib/utils';
import { buttonVariants } from '@/app/ui/button';
import { UserAvatar } from '@/app/components/UserAvatar';
import CrownIcon from '@/app/ui/icons/CrownIcon';
// import { useSession } from 'next-auth/react';
import { useSessionData } from '../useSessionData';
import { useRef, useState } from 'react';
import { MEMBERSHIP_MONTHLY } from '../lib/constants';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/app/ui/dropdown-menu';
import dayjs from 'dayjs';
import { useCallback, useEffect } from 'react';
import { PSUserInfo } from '@/app/types/user';
import { composeSessionUser } from '../lib/clientUtils';
import { tree } from 'next/dist/build/templates/app-page';

interface UserAccountNavProps extends React.HTMLAttributes<HTMLDivElement> {
  user: Pick<
    PSUserInfo,
    'name' | 'image' | 'email' | 'role' | 'membershipExpire'
  >;
}

export default function UserAccountHeader({ user }: UserAccountNavProps) {
  const { data: session, status, update } = useSessionData();
  const cachedUser = session ? composeSessionUser(session) : user;
  const [curUser, setCurUser] = useState(cachedUser);
  const updateLaunchRef = useRef({ launched: false });
  const getMembershipExpire = useCallback(() => {
    return dayjs(curUser?.membershipExpire || '').format('YYYY-MM-DD HH:mm');
  }, [curUser?.membershipExpire]);

  useEffect(() => {
    const updateFunc = async () => {
      const newSession = await update({ from: 'header' });
      if (newSession) {
        const refreshedUser = composeSessionUser(newSession);
        setCurUser(refreshedUser);
      }
    };
    let hasUpdate = updateLaunchRef.current;
    // 只有在status变为authenticated后，update方法才有效
    if (status === 'authenticated') {
      if (!hasUpdate.launched) {
        hasUpdate.launched = true;
        updateFunc();
      }
      setCurUser(cachedUser);
    }
  }, [status]);

  let homeUrl = '/onboarding';
  let homeText = 'Home';
  let showDash = false;
  // if (curUser && isBilledUser(curUser)) {
  // 基于验证码，允许免费用户访问dashboard
  if (curUser && curUser.email) {
    homeUrl = '/patterns';
    homeText = 'Patterns';
    showDash = true;
  }

  return (
    <div className="flex items-center">
      <nav className="flex items-center justify-between  py-4 pr-2 sm:px-4 md:px-6">
        <div className=" flex items-center gap-x-2 sm:gap-4 md:gap-8">
          <Link
            href="/onboarding"
            className="text-[0.75rem] text-[#333333] transition-colors hover:text-gray-900 sm:text-[0.875rem] md:text-[1rem]"
          >
            HOME
          </Link>
          {showDash ? (
            <Link
              href="/patterns"
              className="text-[0.75rem] text-[#333333] transition-colors hover:text-gray-900 sm:text-[0.875rem] md:text-[1rem]"
            >
              PATTERNS
            </Link>
          ) : (
            <></>
          )}
          <Link
            href="/pricing"
            className="text-[0.75rem] text-[#333333] transition-colors hover:text-gray-900 sm:text-[0.875rem] md:text-[1rem]"
          >
            PRICING
          </Link>
          <Link
            href="/contact"
            className="text-[0.75rem] text-[#333333] transition-colors hover:text-gray-900 sm:text-[0.875rem] md:text-[1rem]"
          >
            CONTACT
          </Link>
        </div>
      </nav>
      {curUser && curUser.email ? (
        <div className="flex items-center">
          <div className="mr-2">
            {curUser.role == 2 ? <CrownIcon /> : <div></div>}
          </div>
          <DropdownMenu>
            <DropdownMenuTrigger className="border-none outline-none">
              <UserAvatar
                user={{
                  name: curUser.name,
                  image: curUser.image,
                  role: curUser.role,
                  email: curUser.email,
                }}
                className="h-8 w-8 "
              />
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <div className="flex items-center justify-start gap-2 p-2">
                <div className="flex flex-col space-y-1 leading-none">
                  <div className="">
                    <div className="font-medium">{curUser.name}</div>
                    <div className="mr-2 mt-2 flex">
                      {curUser.role == 2 ? (
                        <div>
                          <CrownIcon />{' '}
                          <span className="text-sm text-gray-500">
                            ({getMembershipExpire()})
                          </span>
                        </div>
                      ) : (
                        <div>
                          <Link
                            href="/pricing"
                            className="text-sm text-[#ff6f20]"
                          >
                            Upgrade
                          </Link>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* {curUser.email && (
                    <p className="w-[200px] truncate text-sm text-muted-foreground">
                      {curUser.email}
                    </p>
                  )} */}
                </div>
              </div>
              <DropdownMenuSeparator />
              {/* <DropdownMenuItem asChild>
                <Link href={homeUrl}>{homeText}</Link>
              </DropdownMenuItem> */}
              <DropdownMenuItem asChild>
                <Link href="/patterns/billing">Billing</Link>
              </DropdownMenuItem>
              <DropdownMenuSeparator />
              <DropdownMenuItem
                className="cursor-pointer"
                onSelect={(event) => {
                  event.preventDefault();
                  signOut({
                    callbackUrl: `${window.location.origin}/login`,
                  });
                }}
              >
                Sign out
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      ) : (
        <div className="flex items-center">
          <Link
            href="/login"
            className={cn(
              buttonVariants({ variant: 'secondary', size: 'sm' }),
              'px-4',
            )}
          >
            Login
          </Link>
        </div>
      )}
    </div>
  );
}
